import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { Box, Flex, Grid, Stack } from '@chakra-ui/react'
import {
  DataGrid,
  TColumnDef,
  IconButton,
  Typography,
  Button,
  useModal,
  Tag,
  Pagination,
} from '@pnld/components-web'

import TotalizerDisplay from '@/components/Display/Totalizer'
import { useUser } from '@/contexts/UserContext'

import {
  IUsersTableData,
  useProfilesController,
  useCombinedUsersController,
  usePaginationController,
} from './controller'
import CreateUserModal from './CreateUserModal'
import FilterForm from './FilterForm'
import {
  TableSkeleton,
  TotalizerSkeleton,
  PaginationSkeleton,
  EmptyState,
} from './SkeletonUsers'

const ActionButtons = (row: IUsersTableData) => {
  const { id } = row
  const navigate = useNavigate()

  const { dsCpf } = useUser()

  if (dsCpf === row.dsCpf) {
    return null
  }

  const handleEdit = () => {
    navigate(`/usuarios/editar/${id}`)
  }

  return (
    <Flex>
      <IconButton
        aria-label="Editar"
        iconName="edit"
        variant="tertiary"
        onClick={handleEdit}
      />
    </Flex>
  )
}

const UserStatusTag = (row: IUsersTableData) => {
  const { dsStatus } = row

  return (
    <Tag variant={dsStatus === 'Ativo' ? 'positive' : 'negative'}>
      {dsStatus}
    </Tag>
  )
}

const Users = () => {
  const { isOpen, onClose, onOpen } = useModal()

  const {
    userProps: { tableData, isLoading, handleFilterSubmit, refetchAll },
    totalizerProps: { totalizers, isLoading: isTotalizersLoading },
    usersData,
    setFilters,
  } = useCombinedUsersController({ onClose })

  const { paginationProps } = usePaginationController(usersData, setFilters)

  const {
    visibleProfiles,
    profileOptions,
    isLoading: isProfilesLoading,
  } = useProfilesController()

  const statusOptions = [
    { label: 'Ativo', value: 'Ativo' },
    { label: 'Inativo', value: 'Inativo' },
  ]

  const columns = useMemo<Array<TColumnDef<IUsersTableData>>>(
    () => [
      {
        accessorKey: 'dsName',
        header: 'Nome do usuário',
      },
      {
        accessorKey: 'profile',
        header: 'Perfil',
      },
      {
        cell: ({ row }) => UserStatusTag(row.original),
        header: 'Status',
      },
      {
        cell: ({ row }) => ActionButtons(row.original),
        header: 'Ações',
      },
    ],
    []
  )

  return (
    <Flex direction="column" w="100%" gap={4}>
      <FilterForm
        onSubmit={handleFilterSubmit}
        profileOptions={profileOptions}
        statusOptions={statusOptions}
      />

      <Grid
        templateColumns={
          isTotalizersLoading
            ? 'repeat(5, 1fr)'
            : `repeat(${visibleProfiles.length + 2}, 1fr)`
        }
        gap={4}
        w="100%"
        h="100%"
      >
        {isTotalizersLoading
          ? Array.from({ length: 5 }).map((_, index) => (
              <TotalizerSkeleton key={index} />
            ))
          : totalizers.map(totalizer => (
              <Box key={totalizer.label}>
                <TotalizerDisplay {...totalizer} />
              </Box>
            ))}
      </Grid>

      <Stack
        flexGrow={1}
        flex={1}
        p={5}
        boxShadow="md"
        borderRadius="base"
        gap={4}
      >
        <Flex justify="space-between" align="center">
          <Typography variant="h-xs" color="brand.primary.dark_1">
            Usuários do sistema
          </Typography>
          <Button
            label="Adicionar usuário"
            iconName="plus"
            onClick={onOpen}
            border="square"
          />
        </Flex>

        <Box overflowX="auto" flexGrow={1}>
          {isLoading ? (
            <TableSkeleton />
          ) : tableData.length === 0 ? (
            <EmptyState />
          ) : (
            <DataGrid
              columns={columns}
              data={tableData}
              hasPagination={false}
            />
          )}
        </Box>

        {isLoading ? (
          <PaginationSkeleton />
        ) : tableData.length > 0 ? (
          <Pagination {...paginationProps} />
        ) : null}

        <CreateUserModal
          isOpen={isOpen}
          onClose={onClose}
          refetchData={refetchAll}
        />
      </Stack>
    </Flex>
  )
}

export default Users
