import React, { useRef } from 'react'

import { GroupBase, SelectInstance } from 'chakra-react-select'
import { useFormik } from 'formik'

import { Box, Flex, Input, InputGroup, ButtonGroup } from '@chakra-ui/react'
import {
  Typography,
  Button,
  Select,
  Form,
  IOptionProps,
} from '@pnld/components-web'

import { TGetUsersParams } from '@/api/users/types'

const initialValues: TGetUsersParams = {
  search: undefined,
  coProfile: undefined,
  dsStatus: undefined,
  page: 1,
  pageSize: 10,
}

interface FilterFormProps {
  onSubmit: (values: TGetUsersParams) => void
  profileOptions: IOptionProps[]
  statusOptions: IOptionProps[]
}

const FilterForm: React.FC<FilterFormProps> = ({
  onSubmit,
  profileOptions,
  statusOptions,
}) => {
  // TODO: Checar se é possível vir vazio da API
  const profileSelectRef =
    useRef<SelectInstance<unknown, false, GroupBase<unknown>>>(null)
  const statusSelectRef =
    useRef<SelectInstance<unknown, false, GroupBase<unknown>>>(null)

  const formik = useFormik({
    initialValues,
    onSubmit: values => {
      onSubmit({
        ...values,
        search: values.search || undefined,
        coProfile: values.coProfile,
        dsStatus: values.dsStatus,
      })
    },
  })

  const handleClearFilters = () => {
    formik.resetForm()
    profileSelectRef.current?.clearValue()
    statusSelectRef.current?.clearValue()
    onSubmit(initialValues)
  }

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Flex w="full" gap={4} p={4} borderRadius="md" boxShadow="md">
        <Flex align="center">
          <Typography
            variant="b-sm"
            color="brand.primary.dark_1"
            whiteSpace="nowrap"
          >
            Filtrar por
          </Typography>
        </Flex>
        <Box w="full">
          <InputGroup gap={4}>
            <Input
              name="search"
              placeholder="Buscar por usuário"
              value={formik.values.search || ''}
              onChange={formik.handleChange}
            />
            <Select
              name="coProfile"
              placeholder="Selecione o perfil"
              options={profileOptions}
              selectRef={profileSelectRef}
              onChange={(newValue: unknown) => {
                const selectedValue = newValue as { value: string } | null
                formik.setFieldValue(
                  'coProfile',
                  selectedValue ? Number(selectedValue.value) : undefined
                )
              }}
            />
            <Select
              name="dsStatus"
              placeholder="Selecione o status"
              options={statusOptions}
              selectRef={statusSelectRef}
              onChange={(newValue: unknown) => {
                const selectedValue = newValue as { value: string } | null
                formik.setFieldValue(
                  'dsStatus',
                  selectedValue ? selectedValue.value : undefined
                )
              }}
            />
          </InputGroup>
        </Box>
        <ButtonGroup>
          <Button
            iconName="filter-circle-xmark"
            variant="highlight"
            label="Limpar filtro"
            border="square"
            onClick={handleClearFilters}
            type="button"
          />
          <Button
            iconName="filter"
            label="Filtrar"
            border="square"
            type="submit"
          />
        </ButtonGroup>
      </Flex>
    </Form>
  )
}

export default FilterForm
