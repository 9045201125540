import React, { useState, useEffect } from 'react'
import { Navigate, Outlet } from 'react-router-dom'

import { jwtDecode } from 'jwt-decode'

import { Stack, Flex } from '@chakra-ui/react'
import { Spinner } from '@pnld/components-web'

import { postAuthRefresh } from '@/api/auth/services'
import { useUser } from '@/contexts/UserContext'
import { useBreadcrumbProps } from '@/data/template'
import { useSidebarItems } from '@/data/template/hooks'
import { stringToBoolean } from '@/utils/string'

import Breadcrumbs from './parts/Breadcrumbs'
import Sidebar from './parts/Sidebar'

const REFRESH_MARGIN = 5 * 60

const TemplatePage: React.FC = () => {
  const [isAuthenticating, setIsAuthenticating] = useState(true)
  const [isAuthenticated, setIsAuthenticated] = useState(false)

  const breadcrumbProps = useBreadcrumbProps()
  const sidebarItems = useSidebarItems()
  const { isLoading } = useUser()

  const refreshAccessToken = async () => {
    const refreshToken = localStorage.getItem('refresh-token')

    if (!refreshToken) {
      setIsAuthenticated(false)
      return
    }

    try {
      const response = await postAuthRefresh({ refresh: refreshToken })
      localStorage.setItem('access-token', response.access)
      setIsAuthenticated(true)

      scheduleTokenRefresh(response.access)
    } catch (error) {
      setIsAuthenticated(false)
    }
  }

  const scheduleTokenRefresh = (token: string) => {
    try {
      const { exp, iat } = jwtDecode<{ exp: number; iat: number }>(token)
      const currentTime = Math.floor(Date.now() / 1000)
      const tokenLifetime = exp - iat
      const timeToExpiration = exp - currentTime

      const refreshTime = Math.max(
        tokenLifetime * 0.5,
        timeToExpiration - REFRESH_MARGIN
      )

      if (timeToExpiration > REFRESH_MARGIN) {
        setTimeout(refreshAccessToken, refreshTime * 1000)
      } else {
        refreshAccessToken()
      }
    } catch {
      setIsAuthenticated(false)
    }
  }

  const checkAuthentication = async () => {
    const accessToken = localStorage.getItem('access-token')
    const refreshToken = localStorage.getItem('refresh-token')

    if (!accessToken || !refreshToken) {
      setIsAuthenticated(false)
      setIsAuthenticating(false)
      return
    }

    try {
      scheduleTokenRefresh(accessToken)
      setIsAuthenticated(true)
    } catch (error) {
      refreshAccessToken()
    }

    setIsAuthenticating(false)
  }

  useEffect(() => {
    if (!stringToBoolean(process.env.REACT_APP_DISABLE_AUTH)) {
      checkAuthentication()
    } else {
      setIsAuthenticated(true)
      setIsAuthenticating(false)
    }
  }, [])

  if (isAuthenticating || isLoading) {
    return (
      <Flex align="center" justify="center" w="100" h="100vh">
        <Spinner size="xl" color="brand.highlight.dark_2" />
      </Flex>
    )
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" />
  }

  return (
    <Sidebar {...sidebarItems}>
      <Stack flexGrow={1} spacing="4">
        <Flex>
          <Breadcrumbs {...breadcrumbProps} />
        </Flex>
        <Outlet />
      </Stack>
    </Sidebar>
  )
}

export default TemplatePage
