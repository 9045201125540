import { AxiosError } from 'axios'

import { useMutation, UseMutationOptions } from '@tanstack/react-query'

import { uploadFileTus } from './services'
import { TUploadFileParams, TUploadFileResponse } from './types'

export const useMutationUploadFile = (
  options?: UseMutationOptions<
    TUploadFileResponse,
    AxiosError,
    TUploadFileParams
  >
) => {
  return useMutation<TUploadFileResponse, AxiosError, TUploadFileParams>(
    params => uploadFileTus(params),
    options
  )
}
