import Uppy, { UploadResult } from '@uppy/core'
import Tus from '@uppy/tus'

import { TUploadFileParams, TUploadFileResponse } from './types'

export const createTusUploader = () => {
  return new Uppy({
    restrictions: {
      maxNumberOfFiles: 1,
      allowedFileTypes: ['.csv'],
    },
    autoProceed: false,
  }).use(Tus, {
    endpoint: `${process.env.REACT_APP_API_URL}/tus/`,
    chunkSize: 5 * 1024 * 1024,
    retryDelays: [0, 1000, 3000, 5000],
    removeFingerprintOnSuccess: true,
    withCredentials: true,
  })
}

export const uploadFileTus = async (
  params: TUploadFileParams
): Promise<TUploadFileResponse> => {
  return new Promise((resolve, reject) => {
    const uppy = createTusUploader()

    try {
      uppy.addFile({
        name: params.file.name,
        data: params.file,
        type: params.file.type,
      })

      uppy.on(
        'complete',
        (
          result: UploadResult<Record<string, unknown>, Record<string, unknown>>
        ) => {
          const uploadedFile = result.successful?.[0]
          if (uploadedFile?.response) {
            const tusResponse = uploadedFile.response as { uploadURL?: string }
            resolve({
              success: true,
              fileUrl: tusResponse.uploadURL,
            })
          } else {
            reject(
              new Error('Não foi possível realizar o upload! Tente novamente.')
            )
          }
        }
      )

      uppy.on('error', error => {
        reject(new Error(`Ocorreu um erro ao realizar o upload: ${error}`))
      })

      uppy.upload()
    } catch (error) {
      reject(
        new Error(
          `Ocorreu um erro ao realizar o upload: ${
            error instanceof Error ? error.message : 'Erro desconhecido!'
          }`
        )
      )
    }
  })
}
