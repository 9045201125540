import http from '@/api/http'

import {
  TGetPredictionsPreviewResponse,
  TGetPredictionParams,
  TGetPredictionsResponse,
  TGetCheckPredictionsResponse,
} from './types'

export const getPredictionsPreview = async (filters: TGetPredictionParams) => {
  const response = await http.get<TGetPredictionsPreviewResponse>(
    '/predictions/preview/',
    {
      params: filters,
    }
  )

  return response.data
}

export const getPredictions = async (filters: TGetPredictionParams) => {
  const response = await http.get<TGetPredictionsResponse>('/predictions/', {
    params: filters,
  })

  return response.data
}

export const getCheckPredictions = async () => {
  const response = await http.get<TGetCheckPredictionsResponse>(
    '/predictions/check_status/'
  )

  return response.data
}
