import React from 'react'

import { Box, SimpleGrid, Stack, Flex } from '@chakra-ui/react'
import { Input, Select, Typography, Form } from '@pnld/components-web'

import { useMyAccountController } from './controller'
import ProfileSkeleton from './skeletonProfile'
import S from './styles'

interface IFormValues {
  name: string
  email: string
  cellphone: string
  perfil: string
  status: string
}

const Profile: React.FC = () => {
  const { myAccount, isLoading } = useMyAccountController()

  if (isLoading) {
    return <ProfileSkeleton />
  }

  const noDataText = 'Não informado'

  const initialValues: IFormValues = {
    name: myAccount?.noUserName || noDataText,
    email: myAccount?.dsGovbrEmail || noDataText,
    // TODO: Esperar a implementação do campo de telefone
    cellphone: noDataText,
    perfil: myAccount?.profile.dsDescription || '',
    status: myAccount?.dsStatus || '',
  }

  return (
    <Flex direction="column">
      <Flex
        direction="column"
        padding="5"
        gap="12"
        borderRadius="0.3125rem"
        boxShadow="0px 4px 4px 0px rgba(0, 0, 0, 0.25)"
      >
        <Flex
          color="white"
          borderRight="1px"
          padding="6"
          gap="6"
          align="center"
          direction={{ base: 'column', md: 'row' }}
          justify="center"
          marginBottom={2}
        >
          <S.TopContainer>
            <S.AvatarContainer>
              <S.StyledAvatar />
            </S.AvatarContainer>
            <Stack>
              <S.UserNameText>{myAccount?.noUserName}</S.UserNameText>
              <S.UserCPFText>{myAccount?.dsCpf}</S.UserCPFText>
            </Stack>
          </S.TopContainer>
        </Flex>

        <Form
          initialValues={initialValues}
          onSubmit={() => {}}
          enableReinitialize
        >
          <Box width="100%">
            <Flex direction="column" gap={12}>
              <Flex direction="column" gap={4}>
                <Typography variant="h-xs" color="brand.primary.dark_1">
                  Dados do usuário
                </Typography>
                <SimpleGrid columns={1} spacing={4}>
                  <Input name="name" label="Nome completo" isDisabled />
                </SimpleGrid>
                <SimpleGrid columns={2} spacing={4}>
                  <Input name="cellphone" label="Telefone" isDisabled />
                  <Input name="email" label="E-mail" isDisabled />
                </SimpleGrid>
              </Flex>
              <Flex direction="column" gap={4}>
                <Typography variant="h-xs" color="brand.primary.dark_1">
                  Permissões do usuário
                </Typography>
                <SimpleGrid columns={2} spacing={4}>
                  <Select
                    name="perfil"
                    label="Perfil"
                    disabled
                    options={[
                      {
                        label: myAccount?.profile.dsDescription || '',
                        value: myAccount?.profile.dsDescription || '',
                      },
                    ]}
                  />
                  <Select
                    name="status"
                    label="Status"
                    disabled
                    options={[
                      {
                        label: myAccount?.dsStatus || '',
                        value: myAccount?.dsStatus || '',
                      },
                    ]}
                  />
                </SimpleGrid>
              </Flex>
            </Flex>
          </Box>
        </Form>
      </Flex>
    </Flex>
  )
}

export default Profile
