import { AxiosError } from 'axios'

import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query'

import { IErrorDTO } from '@/DTOs/errorDTO'

import {
  getPredictionsPreview,
  getPredictions,
  getCheckPredictions,
} from './services'
import {
  TGetPredictionParams,
  TGetPredictionsPreviewResponse,
  TGetPredictionsResponse,
  TGetCheckPredictionsResponse,
} from './types'

export const useQueryGetPredictionsPreview = (
  filters: TGetPredictionParams,
  options?: UseQueryOptions<
    TGetPredictionsPreviewResponse,
    AxiosError<IErrorDTO>
  >
) => {
  const queryFunction = () => getPredictionsPreview(filters)

  return useQuery<TGetPredictionsPreviewResponse, AxiosError<IErrorDTO>>(
    useQueryGetPredictionsPreview.getFullQueryKey(filters),
    queryFunction,
    options
  )
}

useQueryGetPredictionsPreview.getFullQueryKey = (
  filters: TGetPredictionParams
): QueryKey => ['users', filters]

useQueryGetPredictionsPreview.queryKey = ['users']

export const useQueryGetPredictions = (
  filters: TGetPredictionParams,
  options?: UseQueryOptions<TGetPredictionsResponse, AxiosError<IErrorDTO>>
) => {
  const queryFunction = () => getPredictions(filters)

  return useQuery<TGetPredictionsResponse, AxiosError<IErrorDTO>>(
    useQueryGetPredictions.getFullQueryKey(filters),
    queryFunction,
    options
  )
}

useQueryGetPredictions.getFullQueryKey = (
  filters: TGetPredictionParams
): QueryKey => ['users', filters]

useQueryGetPredictions.queryKey = ['users']

export const useQueryGetCheckPredictions = (
  options?: UseQueryOptions<TGetCheckPredictionsResponse, AxiosError<IErrorDTO>>
) => {
  const queryFunction = () => getCheckPredictions()

  return useQuery<TGetCheckPredictionsResponse, AxiosError<IErrorDTO>>(
    ['users'],
    queryFunction,
    options
  )
}
