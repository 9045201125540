import React from 'react'

import * as yup from 'yup'

import { Text, Alert } from '@chakra-ui/react'
import {
  Icon,
  Typography,
  Form,
  Input,
  Button,
  Checkbox,
  Switch,
  Select,
  Divider,
  Box,
  Stack,
  Flex,
  useCheckboxGroup,
  Spinner,
} from '@pnld/components-web'

import { TStages } from '@/api/auxiliaries/types'
import { IEducationStageDTO } from '@/DTOs/auxiliaries/auxiliaries.DTO'

import {
  usePredictionsController,
  allSpheresValues,
  allStagesValues,
} from './controller'

interface ISelectOption extends IEducationStageDTO {
  value: string
  label: string
}

interface ISearchFormValues {
  year: string
  all_entities: boolean
  child_education: Array<ISelectOption>
  basic_education: Array<ISelectOption>
  high_school: Array<ISelectOption>
  technical_course: Array<ISelectOption>
  eja: Array<ISelectOption>
}

const Predictions = () => {
  const {
    isPredictionsPreviewLoading,
    educationStages,
    isEducationStagesLoading,
    availableUntilYear,
    predictionYearsOptions,
    isPredictionYearsLoading,
    setPredictionsFilters,
    isSearchEmpty,
    setIsSearchEmpty,
    refetchCheckPredictions,
    isCheckingPredictions,
  } = usePredictionsController()
  const [allEntities, setAllEntities] = React.useState(false)
  const [advancedSearch, setAdvancedSearch] = React.useState(false)
  const [processingPrediction, setProcessingPrediction] = React.useState(false)
  const {
    value: stagesCheckboxesValues,
    getCheckboxProps: getStagesCheckboxProps,
    setValue: setStagesCheckboxes,
  } = useCheckboxGroup({
    defaultValue: [],
  })
  const {
    value: spheresCheckboxesValues,
    getCheckboxProps: getSpheresCheckboxProps,
    setValue: setSpheresCheckboxes,
  } = useCheckboxGroup({
    defaultValue: [],
  })

  const getEducationStagesOptions = (key: TStages) => {
    const rawOptions = educationStages?.[key]
    const options = rawOptions?.map(option => ({
      value: String(option.co_education_stage),
      label: option.no_education_stage,
      ...option,
    }))

    return options as Array<{
      value: string
      label: string
    }>
  }

  const handleSearch = (values: ISearchFormValues) => {
    setIsSearchEmpty(false)
    const educationStages = [
      ...values.child_education,
      ...values.basic_education,
      ...values.high_school,
      ...values.technical_course,
      ...values.eja,
    ]

    setPredictionsFilters({
      nuYearPrediction: String(values.year),
      coEducationStage: advancedSearch ? educationStages.join(',') : '',
      dsSphere: spheresCheckboxesValues.join(','),
      dsEducationGroup: stagesCheckboxesValues.join(','),
    })
  }

  const handleCheckPredictions = async (values: ISearchFormValues) => {
    const { data } = await refetchCheckPredictions()
    setProcessingPrediction(data?.processingPrediction || false)

    if (!data?.processingPrediction) {
      handleSearch(values)
    }
  }

  return (
    <Stack gap={6}>
      <Flex
        p={4}
        gap={4}
        bg="brand.primary.light_2"
        borderRadius="md"
        align="center"
      >
        <Icon name="info-circle" size="xs" />
        <Text fontWeight="semibold" color="brand.neutral.dark_2">
          Predição disponível até o ano de {availableUntilYear || ''}.
        </Text>
      </Flex>
      {processingPrediction && (
        <Alert
          status="warning"
          w="full"
          maxW="unset"
          gap={4}
          mt="0 !important"
          py="3xs"
        >
          <Icon name="exclamation-triangle" size="xs" variant="highlight" />
          <Stack color="black">
            <Text fontWeight="bold">
              A predição do ano de 2026 está em andamento!
            </Text>
            <Text mt="0 !important">
              Em até 48h os dados da predição estarão disponíveis você.
            </Text>
          </Stack>
        </Alert>
      )}
      <Stack gap={4} boxShadow="md" p={4} borderRadius="base">
        <Typography variant="h-xs" color="brand.primary.dark_2">
          Predições
        </Typography>
        <Stack gap={4}>
          <Form
            onSubmit={handleCheckPredictions}
            initialValues={{
              year: '',
              all_entities: false,
              child_education: [],
              basic_education: [],
              high_school: [],
              technical_course: [],
              eja: [],
            }}
            validationSchema={yup.object().shape({
              year: yup.string().required('Obrigatório'),
            })}
          >
            <Stack gap={4}>
              <Flex justify="space-between">
                <Box flex={2}>
                  <Select
                    name="year"
                    options={predictionYearsOptions}
                    placeholder="Selecione um ano de atendimento"
                    isLoading={isPredictionYearsLoading}
                    loadingMessage={() => 'Carregando...'}
                  />
                </Box>
                <Flex gap={2} flex={3} justify="flex-end">
                  <Button
                    label="Pesquisa avançada"
                    variant="tertiary"
                    disabled={
                      allEntities ||
                      isPredictionsPreviewLoading ||
                      isCheckingPredictions
                    }
                    onClick={() => {
                      setAdvancedSearch(!advancedSearch)
                      setStagesCheckboxes([])
                    }}
                  />
                  <Button
                    type="submit"
                    label="Buscar"
                    variant="secondary"
                    iconName="search"
                    isLoading={
                      isPredictionsPreviewLoading || isCheckingPredictions
                    }
                  />
                </Flex>
              </Flex>
              <Flex gap={4}>
                <Typography variant="b-sm" color="brand.neutral.dark_2">
                  Esfera:
                </Typography>
                <Checkbox
                  {...getSpheresCheckboxProps({
                    value: 'PUBLICA',
                    disabled: allEntities,
                  })}
                  label="Pública"
                />
                <Checkbox
                  {...getSpheresCheckboxProps({
                    value: 'PRIVADA_CONVENIADA',
                    disabled: allEntities,
                  })}
                  label="Privada (conveniadas ao PNLD)"
                />
                <Checkbox
                  {...getSpheresCheckboxProps({
                    value: 'PRIVADA',
                    disabled: allEntities,
                  })}
                  label="Privada (não conveniadas ao PNLD)"
                />
              </Flex>
              <Flex gap={4} flexWrap="wrap">
                <Typography variant="b-sm" color="brand.neutral.dark_2">
                  Etapa de ensino:
                </Typography>
                <Checkbox
                  {...getStagesCheckboxProps({
                    value: 'EDUCACAO_INFANTIL',
                    disabled: allEntities || advancedSearch,
                  })}
                  label="Ensino infantil"
                />
                <Checkbox
                  {...getStagesCheckboxProps({
                    value: 'ENSINO_FUNDAMENTAL_INICIAL',
                    disabled: allEntities || advancedSearch,
                  })}
                  label="Ensino fundamental anos iniciais"
                />
                <Checkbox
                  {...getStagesCheckboxProps({
                    value: 'ENSINO_FUNDAMENTAL_FINAL',
                    disabled: allEntities || advancedSearch,
                  })}
                  label="Ensino fundamental anos finais"
                />
                <Checkbox
                  {...getStagesCheckboxProps({
                    value: 'ENSINO_MEDIO',
                    disabled: allEntities || advancedSearch,
                  })}
                  label="Ensino médio"
                />
                <Checkbox
                  {...getStagesCheckboxProps({
                    value: 'EJA',
                    disabled: allEntities || advancedSearch,
                  })}
                  label="EJA"
                />
              </Flex>
              <Flex align="center" gap={2}>
                <Text fontWeight="semibold" fontSize="2xs">
                  Todas as entidades
                </Text>
                <Switch
                  name="all_entities"
                  size="lg"
                  disabled={advancedSearch || isPredictionsPreviewLoading}
                  onChange={e => {
                    setAllEntities(e.target.checked)
                    if (e.target.checked) {
                      setSpheresCheckboxes(allSpheresValues)
                      setStagesCheckboxes(allStagesValues)
                    } else {
                      setSpheresCheckboxes([])
                      setStagesCheckboxes([])
                    }
                  }}
                />
                <Typography variant="b-sm" color="brand.neutral.dark_2">
                  {allEntities ? 'Sim' : 'Não'}
                </Typography>
              </Flex>
              {advancedSearch && (
                <>
                  <Divider />
                  <Flex gap={4}>
                    <Select
                      label="Educação infantil"
                      name="child_education"
                      options={getEducationStagesOptions('EDUCACAO_INFANTIL')}
                      placeholder="Selecione uma opção"
                      isMulti
                      multiValueLimitDisplayed={1}
                      isLoading={isEducationStagesLoading}
                    />
                    <Select
                      label="Ensino fundamental"
                      name="basic_education"
                      options={getEducationStagesOptions('ENSINO_FUNDAMENTAL')}
                      placeholder="Selecione uma opção"
                      isMulti
                      multiValueLimitDisplayed={1}
                      isLoading={isEducationStagesLoading}
                    />
                    <Select
                      label="Ensino médio"
                      name="high_school"
                      options={getEducationStagesOptions('ENSINO_MEDIO')}
                      placeholder="Selecione uma opção"
                      isMulti
                      multiValueLimitDisplayed={1}
                      isLoading={isEducationStagesLoading}
                    />
                  </Flex>
                  <Flex gap={4}>
                    <Select
                      label="Curso técnico"
                      name="technical_course"
                      options={getEducationStagesOptions('CURSO_TECNICO')}
                      placeholder="Selecione uma opção"
                      isMulti
                      multiValueLimitDisplayed={1}
                      isLoading={isEducationStagesLoading}
                    />
                    <Select
                      label="EJA"
                      name="eja"
                      options={getEducationStagesOptions('EJA')}
                      placeholder="Selecione uma opção"
                      isMulti
                      multiValueLimitDisplayed={1}
                      isLoading={isEducationStagesLoading}
                    />
                  </Flex>
                </>
              )}
            </Stack>
          </Form>
        </Stack>
      </Stack>
      {(isPredictionsPreviewLoading || isCheckingPredictions) && (
        <Flex justify="center" align="center" gap={4}>
          <Spinner color="brand.primary.dark_1" size="md" />
          <Text fontSize="2xs" fontWeight="medium" color="brand.neutral.dark_2">
            Carregando...
          </Text>
        </Flex>
      )}
      {isSearchEmpty && (
        <Stack
          px={8}
          py={12}
          gap={4}
          align="center"
          boxShadow="md"
          borderRadius="base"
        >
          <Icon name="search" size="md" shaped variant="neutral" />
          <Stack>
            <Typography
              variant="s-md"
              color="brand.neutral.dark_2"
              textAlign="center"
            >
              Nenhum resultado encontrado.
            </Typography>
            <Typography
              variant="b-lg"
              color="brand.neutral.dark_2"
              textAlign="center"
            >
              Verifique se os termos foram digitados corretamente e se filtros
              que possam interferir na busca estão selecionados.
            </Typography>
          </Stack>
        </Stack>
      )}
    </Stack>
  )
}

export default Predictions
