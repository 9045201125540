import axios, { InternalAxiosRequestConfig, AxiosResponse } from 'axios'
import applyCaseMiddleware from 'axios-case-converter'

import { IErrorDTO } from '@/DTOs/errorDTO'

const http = applyCaseMiddleware(
  axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  })
)

export const httpWithoutMiddleware = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

const PUBLIC_ROUTES = [
  '/auth/govbr/',
  '/auth/govbr/get-url/',
  '/auth/local/login/',
]

const requestInterceptor = (request: InternalAxiosRequestConfig) => {
  const accessToken = localStorage.getItem('access-token')

  const isPublicRoute = PUBLIC_ROUTES.some(route =>
    request.url?.includes(route)
  )

  if (!isPublicRoute && accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`
  }

  return request
}

const responseInterceptor = (response: AxiosResponse) => {
  return response
}

const errorInterceptor = (error: any) => {
  if (error.response) {
    const path = error.response.config.url
    const status = error.response.status

    if (status === 401 && !path?.includes('/auth')) {
      const errorResponse = error.response.data as IErrorDTO
      const errors = errorResponse.errors.map(error => error.code)

      if (errors.includes('not_authenticated')) {
        localStorage.removeItem('access-token')
        localStorage.removeItem('refresh-token')

        window.location.href = '/login'
      }
    }
  }

  return Promise.reject(error)
}

http.interceptors.request.use(requestInterceptor)
http.interceptors.response.use(responseInterceptor, errorInterceptor)

httpWithoutMiddleware.interceptors.request.use(requestInterceptor)
httpWithoutMiddleware.interceptors.response.use(
  responseInterceptor,
  errorInterceptor
)

export default http
